import { Container, Flex, Heading, List, ListItem, Text } from '@chakra-ui/react';
import { graphql } from 'gatsby';
import React, { FC } from 'react';
import { GalleryCard } from '../components/GalleryCard';
import { Layout } from '../components/Layout';
import { QuestionCard } from '../components/QuestionCard';
import '../global.css';
import { GalleriesQueryResult } from '../types';

interface Props {
    data: GalleriesQueryResult;
}

const Page: FC<Props> = ({ data }) => {
    return (
        <Layout title="Balustrady nierdzewne tarasowe zewnętrzne">
            <Container alignSelf="center" maxW={['container.sm', 'container.xl']} px={['4', '10']} mt={['4', '12']}>
                <Flex gap="10" direction="column">
                    <Heading
                        id="realizacje"
                        scrollMarginTop="4"
                        mx={['12', '0']}
                        as="h2"
                        size="2xl"
                        variant="secondary"
                        alignSelf="flex-start"
                    >
                        Balustrady tarasowe
                    </Heading>

                    <GalleryCard fileEdges={data.taras.edges} id="realizacje-tarasy" title="Balustrady tarasowe" />

                    <QuestionCard defaultExpanded title="Balustrady nierdzewne na tarasy zewnętrzne">
                        <Heading as="h4" size="md">
                            Bezpieczeństwo
                        </Heading>
                        <Text mt={['2']}>
                            Balustrady tarasowe to ważny element zapewniający bezpieczeństwo na Twoim tarasie. Nasze
                            produkty są wykonane z najwyższej jakości materiałów i spełniają normy bezpieczeństwa.
                        </Text>

                        <Heading as="h4" size="md" mt={['6']}>
                            Estetyka
                        </Heading>
                        <Text mt={['2']}>
                            Balustrada może stanowić piękny dodatek do Twojego tarasu, który podkreśli jego styl i
                            charakter.
                        </Text>

                        <Heading as="h4" size="md" mt={['6']}>
                            Wysoka jakość
                        </Heading>
                        <Text mt={['2']}>
                            Używamy najwyższej jakości materiałów, co gwarantuje trwałość i długą żywotność wykonanych
                            konstrukcji.
                        </Text>

                        <Heading as="h4" size="md" mt={['6']}>
                            Montaż
                        </Heading>
                        <Text mt={['2']}>
                            Możesz mieć pewność, że zamontowane przez nas balustrady odpowiadają normom bezpieczeństwa
                        </Text>
                    </QuestionCard>
                </Flex>
            </Container>
        </Layout>
    );
};

export default Page;

/*
TODO:
* improve SEO (roobts.txt)
* play with the images sizes & formats (only jpg for full?)
* improve desktop gallery

*/
export const query = graphql`
    fragment MyGallery on FileConnection {
        edges {
            node {
                id
                name
                thumb: childImageSharp {
                    gatsbyImageData(height: 300, placeholder: BLURRED, layout: CONSTRAINED)
                }
                full: childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
                }
            }
        }
    }

    query TarQuery {
        taras: allFile(filter: { relativeDirectory: { eq: "galleries/taras" } }) {
            ...MyGallery
        }
    }
`;
